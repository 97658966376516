.App {
  text-align: center;
  height: 100%;
  width: 100%;
  background-color: #131820;
}

.main-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  color: rgba(255, 255, 255, 0.95);
  font-size: 6vw;
  font-style: italic;
  font-weight: 900;
  user-select: none;
  margin: 10px;
}
